import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import SEO from 'components/SEO'
import Select from 'components/Select'
import CategoryCard from 'components/CategoryCard'
import PartnersLinks from 'components/PartnersLinks'

import styles from './CategoryList.module.scss'

const AlphabeticallyCategoriesPage = ({ data, pageContext }) => {
  const { categories, site: { siteMetadata }, banner } = data
  const { categoryCounts } = pageContext

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/categories/`,
          "name": "Categories"
        }
      },
    ]
  }

  const sloppyPartners = [
    {name: 'anal fuck', url: 'https://xxxhdtube8.com/category/Anal'},
  ]
  
  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`Chose between dozens of cam porn categories selected for you | SloppyDeep`}
      description={`Watch all the best camgirls private shows. Pick your favorite category, explore our cam models and play your free porn video recording. Only from Sloppydeep.com - the top cam teen tube`}
      keywords={`cam porn categories, cam girl videos, best cam tube, cam teen tube`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/categories/`}
      robots='index, follow'
    />
    <Helmet>
      { /* prefetch for thumbnails */}
      <link rel='preconnect dns-prefetch' href='https://galleryn0.awemwh.com' crossorigin />
    </Helmet>
    <main className={`${styles.categoryListScreen} screen-container`}>
      <div className={styles.titleBlock}>
        <div className={styles.sectionTitle}>Categories</div>
        <div className={styles.sectionFilter}>
          <Select selected={0} options={[{ text: 'A to Z', to: '/categories/' }, { text: 'Popular', to: '/categories/popular/' }]} />
        </div>
      </div>
      <div className={`${styles.categoriesBlock} category-cards-container`}>
        {categories.nodes.map((category, idx) => <CategoryCard key={idx} data={{ ...category, count: categoryCounts[category.name] }} />)}
      </div>

      {sloppyPartners.length > 0 &&
        <>
          <div className={styles.titleBlock}>Sloppy Partners</div>
          <div className={styles.partnersLinksBlock}>
            <PartnersLinks links={sloppyPartners} />
          </div>
        </>
      }

    </main>
    </>
  )
}

export const query = graphql`
  query AlphabeticallyCategoriesPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    categories: allSloppyDeepCategory(sort: {fields: name}) {
      nodes {
        slug
        name
        listedImageAlt
        listedImage {
          childImageSharp {
            fluid(maxWidth: 353, maxHeight: 450, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

export default AlphabeticallyCategoriesPage
